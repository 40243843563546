import React from 'react';
// import sections
import TermsSection from '../components/sections/TermsSection';
const Terms = () => {
  return (
    <>
    <TermsSection className="illustration-section-01" />
    </>
  );
}

export default Terms;