import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const TermsSection = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }   

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="bg-white">
            <div className="max-w-screen-2xl mx-auto lg:py-20 py-10 px-4 sm:px-10">
              <div className="mb-8 lg:mb-12 last:mb-0">
                <h2 className="text-xl xl:text-2xl xl:leading-7 font-semibold font-serif mb-2 lg:mb-4">
                  Welcome to Mobe
                </h2>
                <div className="font-sans leading-7">
                  <p>
                    These terms and conditions outline the rules and regulations for
                    the use of Mobe's Website, located at
                    https://www.ridemobe.com/ and mobile apps. By accessing this website we assume you
                    accept these terms and conditions. Do not continue to use
                    Mobe if you do not agree to take all of the terms and
                    conditions stated on this page.
                  </p>
                  <p>
                    The following terminology applies to these Terms and Conditions,
                    Privacy Statement and Disclaimer Notice and all Agreements:
                    "Client", "You" and "Your" refers to you, the person log on this
                    website and compliant to the Company’s terms and conditions.
                    "The Company", "Ourselves", "We", "Our" and "Us", refers to our
                    Company. "Party", "Parties", or "Us", refers to both the Client
                    and ourselves. All terms refer to the offer, acceptance and
                    consideration of payment necessary to undertake the process of
                    our assistance to the Client in the most appropriate manner for
                    the express purpose of meeting the Client’s needs in respect of
                    provision of the Company’s stated services, in accordance with
                    and subject to, prevailing law of Netherlands. Any use of the
                    above terminology or other words in the singular, plural,
                    capitalization and/or he/she or they, are taken as
                    interchangeable and therefore as referring to same.
                  </p>
                </div>
              </div>
              <div className="mb-6 lg:mb-12 last:mb-0">
                <h2 className="text-xl xl:text-2xl xl:leading-7 font-semibold font-serif mb-2 lg:mb-4">
                  Cookies
                </h2>
                <div className="font-sans leading-7">
                  <p>
                    We employ the use of cookies. By accessing Mobe, you
                    agreed to use cookies in agreement with the Mobe's Privacy
                    Policy. Most interactive websites use cookies to let us retrieve
                    the user’s details for each visit. Cookies are used by our
                    website to enable the functionality of certain areas to make it
                    easier for people visiting our website. Some of our
                    affiliate/advertising partners may also use cookies.
                  </p>
                </div>
              </div>
              <div className="mb-8 lg:mb-12 last:mb-0">
              <h2 className="text-xl xl:text-2xl xl:leading-7 font-semibold font-serif mb-2 lg:mb-4">
                License
              </h2>
              <div className="font-sans leading-7">
                <p>
                  Unless otherwise stated, Mobe and/or its licensors own the
                  intellectual property rights for all material on Mobe. All
                  intellectual property rights are reserved. You may access this
                  from Mobe for your own personal use subjected to
                  restrictions set in these terms and conditions.This Agreement
                  shall begin on the date hereof. Our Terms and Conditions were
                  created with the help of the Terms And Conditions Generator.
                </p>

                <ul>
                  <strong className="mb-2">You must not:</strong>
                  <li>
                    1. Identifiers (e.g. name, mailing address, email address,
                    phone number, credit/debit card number)
                  </li>
                  <li>
                    2. Characteristics of protected classifications (e.g. gender,
                    age)
                  </li>
                  <li>
                    3. Commercial information (e.g. products or services
                    purchased, purchase history)
                  </li>
                  <li>
                    4. Internet or other electronic network activity (e.g. browse
                    or search history)
                  </li>
                  <li> 5. Geo location data (e.g. latitude or longitude)</li>
                  <li>
                    6. Audio, electronic, visual, or similar information (e.g.
                    recording of Guest service calls)
                  </li>
                  <li>
                    7. Inferences drawn from any of the above (e.g. preferences or
                    characteristics)
                  </li>
                </ul>
                <p>
                  Parts of this website offer an opportunity for users to post and
                  exchange opinions and information in certain areas of the
                  website. Mobe does not filter, edit, publish or review
                  Comments prior to their presence on the website. Comments do not
                  reflect the views and opinions of Mobe,its agents and/or
                  affiliates. Comments reflect the views and opinions of the
                  person who post their views and opinions. To the extent
                  permitted by applicable laws, Mobe shall not be liable for
                  the Comments or for any liability, damages or expenses caused
                  and/or suffered as a result of any use of and/or posting of
                  and/or appearance of the Comments on this website.
                </p>
              </div>
              <div className="my-6 lg:my-8 last:mb-0">
                <h2 className="text-xl xl:text-2xl xl:leading-7 font-semibold font-serif mb-2 lg:mb-4">
                  Content Liability
                </h2>
                <div className="font-sans leading-7">
                  <p>
                    We shall not be hold responsible for any content that appears
                    on your Website. You agree to protect and defend us against
                    all claims that is rising on your Website. No link(s) should
                    appear on any Website that may be interpreted as libelous,
                    obscene or criminal, or which infringes, otherwise violates,
                    or advocates the infringement or other violation of, any third
                    party rights.Without prior approval and written permission,
                    you may not create frames around our Webpages that alter in
                    any way the visual presentation or appearance of our Website.
                  </p>
                </div>
              </div>
              <div className="my-6 lg:my-8 last:mb-0">
                <h2 className="text-xl xl:text-2xl xl:leading-7 font-semibold font-serif mb-2 lg:mb-4">
                  Your Privacy
                </h2>
                <div className="font-sans leading-7">
                  <p><span className="text-orange-500">Privacy Policy</span></p>
                </div>
              </div>
              <div className="my-6 lg:my-8 last:mb-0">
                <h2 className="text-xl xl:text-2xl xl:leading-7 font-semibold font-serif mb-2 lg:mb-4">
                  Reservation of Rights
                </h2>
                <div className="font-sans leading-7">
                  <p>
                    We reserve the right to request that you remove all links or
                    any particular link to our Website. You approve to immediately
                    remove all links to our Website upon request. We also reserve
                    the right to amen these terms and conditions and it’s linking
                    policy at any time. By continuously linking to our Website,
                    you agree to be bound to and follow these linking terms and
                    conditions./
                  </p>
                </div>
              </div>
              <div className="my-6 lg:my-8 last:mb-0">
                <h2 className="text-xl xl:text-2xl xl:leading-7 font-semibold font-serif mb-2 lg:mb-4">
                  Removal of links from our website
                </h2>
                <div className="font-sans leading-7">
                  <p>
                    If you find any link on our Website that is offensive for any
                    reason, you are free to contact and inform us any moment. We
                    will consider requests to remove links but we are not
                    obligated to or so or to respond to you directly. We do not
                    ensure that the information on this website is correct, we do
                    not warrant its completeness or accuracy; nor do we promise to
                    ensure that the website remains available or that the material
                    on the website is kept up to date.
                  </p>
                </div>
              </div>
              <div className="my-6 lg:my-8 last:mb-0">
                <h2 className="text-xl xl:text-2xl xl:leading-7 font-semibold font-serif mb-2 lg:mb-4">
                  Disclaimer
                </h2>
                <div className="font-sans leading-7">
                  <p>
                    To the maximum extent permitted by applicable law, we exclude
                    all representations, warranties and conditions relating to our
                    website and the use of this website. Nothing in this
                    disclaimer will:
                  </p>
                  <ul>
                    <li>
                      1. limit or exclude our or your liability for death or
                      personal injury;
                    </li>
                    <li>
                      2. limit or exclude our or your liability for fraud or
                      fraudulent misrepresentation;
                    </li>
                    <li>
                      3. limit any of our or your liabilities in any way that is
                      not permitted under applicable law; or
                    </li>
                    <li>
                      4. exclude any of our or your liabilities that may not be
                      excluded under applicable law.
                    </li>
                  </ul>
                  <p>
                    The limitations and prohibitions of liability set in this
                    Section and elsewhere in this disclaimer: (a) are subject to
                    the preceding paragraph; and (b) govern all liabilities
                    arising under the disclaimer, including liabilities arising in
                    contract, in tort and for breach of statutory duty. As long as
                    the website and the information and services on the website
                    are provided free of charge, we will not be liable for any
                    loss or damage of any nature.
                  </p>
                </div>
              <h2 className="text-xl xl:text-2xl xl:leading-7 font-semibold font-serif mb-2 lg:mb-4">
                Last updated: June 25, 2024
              </h2>
              <div className="font-sans leading-7">
                <p>
                  At Mobe, accessible from ridemobe.com, one of our
                  main priorities is the privacy of our visitors. This Privacy
                  Policy document contains types of information that is collected
                  and recorded by Mobe and how we use it. If you have
                  additional questions or require more information about our
                  Privacy Policy, do not hesitate to contact us.we may receive
                  additional information about you such as your name, email
                  address, phone number, the contents of the message and/or
                  attachments you may send us, and any other information you may
                  choose to provide. When you register for an Account, we may ask
                  for your contact information, including items such as name,
                  company name, address, email address, and telephone number.
                </p>
                <p>
                  This Privacy Policy applies only to our online activities and is
                  valid for visitors to our website with regards to the
                  information that they shared and/or collect in Mobe. We may
                  receive additional information about you such as your name,
                  email address, phone number, the contents of the message and/or
                  attachments you may send us, we may ask for your contact
                  information, including items such as name, company name,
                  address, email address, and telephone number. This policy is applicable to any information collected offline or via channels
                  other than this website.
                </p>
              </div>
            </div>
            <div className="mb-8 lg:mb-12 last:mb-0">
              <h2 className="text-xl xl:text-2xl xl:leading-7 font-semibold font-serif mb-2 lg:mb-4">
                Consent
              </h2>
              <div className="font-sans leading-7">
                <p>
                  By using our website, you hereby consent to our Privacy Policy
                  and agree to its terms.
                </p>
              </div>
            </div>
            <div className="mb-8 lg:mb-12 last:mb-0">
              <h2 className="text-xl xl:text-2xl xl:leading-7 font-semibold font-serif mb-2 lg:mb-4">
                Information we collect
              </h2>
              <div className="font-sans leading-7">
                <p>
                  The personal information that you are asked to provide, and the
                  reasons why you are asked to provide it, will be made clear to
                  you at the point we ask you to provide your personal
                  information. If you contact us directly, we may receive
                  additional information about you such may choose to provide.
                  When you register for an Account, we may ask for your contact
                  information, including items such as name, company name,
                  address, email address, and telephone number.
                </p>
                <p>
                  Please note that the Company will not ask you to share any
                  sensitive data or information via email or telephone. If you
                  receive any such request by email or telephone, please do not
                  respond/divulge any sensitive data or information and forward
                  the information relating to the same to
                </p>
              </div>
            </div>
            <div className="mb-8 lg:mb-12 last:mb-0">
              <h2 className="text-xl xl:text-2xl xl:leading-7 font-semibold font-serif mb-2 lg:mb-4">
                How we use your information
              </h2>
              <div className="font-sans leading-7">
                <p>
                  We use the information we collect in various ways, including to:
                </p>

                <ol>
                  <li>
                    1. Provide, operate, and maintain our website, to provide you
                    with updates and other information.
                  </li>
                  <li>
                    2. Improve, personalize, and expand our website,and other
                    information relating to the website.
                  </li>
                  <li>
                    3. Understand and analyze how you use our website, to provide
                    you with updates and other information relating to the
                    website.
                  </li>
                  <li>
                    4. Develop new products, services, features, and
                    functionality,and other information relating to the website.
                  </li>
                  <li>
                    5. Communicate with you, either directly or through one of our
                    partners, including for customer service, to provide you with
                    updates.
                  </li>
                  <li>
                    6. Send you emails. To provide you with updates and other
                    information relating to the website, and for marketing and
                    promotional purposes
                  </li>
                  <li>
                    7. Find and prevent fraud. To provide you with updates and
                    other information relating to the website, and for marketing
                    and promotional purposes
                  </li>
                </ol>
              </div>
            </div>
            <div className="mb-8 lg:mb-12 last:mb-0">
              <h2 className="text-xl xl:text-2xl xl:leading-7 font-semibold font-serif mb-2 lg:mb-4">
                Log Files
              </h2>
              <div className="font-sans leading-7">
                <p>
                  Mobe follows a standard procedure of using log files.
                  These files log visitors when they visit websites. All hosting
                  companies do this and a part of hosting. The information
                  collected by log files include internet protocol (IP) addresses,
                  browser type, Internet Service Provider (ISP), date and time
                  stamp, referring/exit pages, and possibly the number of clicks.
                  These are not linked to any information that is personally
                  identifiable. The purpose of the information is for analyzing
                  trends, administering the site, tracking users' movement on the
                  website, and gathering demographic information.
                </p>
              </div>
            </div>
            <div className="mb-8 lg:mb-12 last:mb-0">
              <h2 className="text-xl xl:text-2xl xl:leading-7 font-semibold font-serif mb-2 lg:mb-4">
                Advertising Partners Privacy Policies
              </h2>
              <div className="font-sans leading-7">
                <p>
                  You may consult this list to find the Privacy Policy for each of
                  the advertising partners of Mobe. Third-party ad servers
                  or ad networks uses technologies like cookies, JavaScript, or
                  Web Beacons that are used in their respective advertisements and
                  links that appear on Mobe, which are sent directly to
                  users' browser. They automatically receive your IP address when
                  this occurs. These technologies are used to measure the
                  effectiveness of their advertising campaigns and/or to
                  personalize the advertising content that you see on websites
                  that you visit. Note that Mobe has no access to or control
                  over these cookies that are used by third-party advertisers.
                </p>
              </div>
            </div>
            <div className="mb-8 lg:mb-12 last:mb-0">
              <h2 className="text-xl xl:text-2xl xl:leading-7 font-semibold font-serif mb-2 lg:mb-4">
                Third Party Privacy Policies
              </h2>
              <div className="font-sans leading-7">
                <p>
                  Third-party ad servers or ad networks uses technologies like
                  cookies, JavaScript, or Web Beacons that are used in their
                  respective advertisements and links that appear on Mobe,
                  which are sent directly to users' browser. They automatically
                  receive your IP address when this occurs. These technologies are
                  used to measure the effectiveness of their advertising campaigns
                  and/or to personalize the advertising content that you see on
                  websites that you visit. Note that Mobe has no access to
                  or control over these cookies that are used by third-party
                  advertisers.
                </p>
                <p>
                  Mobe's Privacy Policy does not apply to other advertisers
                  or websites. Thus, we are advising you to consult the respective
                  Privacy Policies of these third-party ad servers for more
                  detailed information. It may include their practices and
                  instructions about how to opt-out of certain options. You can
                  choose to disable cookies through your individual browser
                  options. To know more detailed information about cookie
                  management with specific web browsers, it can be found at the
                  browsers' respective websites.
                </p>
              </div>
            </div>
            <div className="mb-8 lg:mb-12 last:mb-0">
              <h2 className="text-xl xl:text-2xl xl:leading-7 font-semibold font-serif mb-2 lg:mb-4">
                CCPA Privacy Rights
              </h2>
              <div className="font-sans leading-7">
                <p>
                  Under the CCPA, among other rights, California consumers have
                  the right to: Request that a business that collects a consumer's
                  personal data disclose the categories and specific pieces of
                  personal data that a business has collected about consumers.
                  Request that a business delete any personal data about the
                  consumer that a business has collected. Request that a business
                  that sells a consumer's personal data, not sell the consumer's
                  personal data. If you make a request, we have one month to
                  respond to you. If you would like to exercise any of these
                  rights, please contact us.
                </p>
              </div>
            </div>
            <div className="mb-8 lg:mb-12 last:mb-0">
              <h2 className="text-xl xl:text-2xl xl:leading-7 font-semibold font-serif mb-2 lg:mb-4">
                Children's Information
              </h2>
              <div className="font-sans leading-7">
                <p>
                  Another part of our priority is adding protection for children
                  while using the internet. We encourage parents and guardians to
                  observe, participate in, and/or monitor and guide their online
                  activity.Request that a business delete any personal data about
                  the consumer that a business has collected. If you make a
                  request, we have one month to respond to you. If you would like
                  to exercise any of these rights, please contact us.
                </p>
                <p>
                  Mobe does not knowingly collect any Personal Identifiable
                  Information from children under the age of 13. If you think that
                  your child provided this kind of information on our website, we
                  strongly encourage you to contact us immediately and we will do
                  our best efforts to promptly remove such information from our
                  records.
                </p>
              </div>
            </div>
          </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

TermsSection.propTypes = propTypes;
TermsSection.defaultProps = defaultProps;

export default TermsSection;