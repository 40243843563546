import React from 'react';
// import sections
import PrivacySection from '../components/sections/PrivacySection';
const Privacy = () => {
  return (
    <>
    <PrivacySection className="illustration-section-01" />
    </>
  );
}

export default Privacy;